import { FC, useState } from 'react';
import { Button, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faMaximize, faExpand } from '@fortawesome/pro-solid-svg-icons';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import { useTranslation } from 'react-i18next';
import styles from './greenhouseVisualisation.module.scss';

interface GreenhouseVisualizationProps {
  visible: boolean;
  onClose: () => void;
}

type TabKey = 'schematic' | '2d' | '3d';

export const GreenhouseVisualization: FC<GreenhouseVisualizationProps> = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const [isMinimized, setIsMinimized] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 600, height: 500 });
  const [activeTab, setActiveTab] = useState<TabKey>('schematic');

  if (!visible) return null;

  const items = [
    {
      key: 'schematic',
      label: t('visualization.schematic.title'),
      children: (
        <div className={`${styles.visualisationContent} ${styles.schematic}`}>
          <img src="/mock-images/schematisch.png" alt="Schematische Weergave" />
        </div>
      ),
    },
    {
      key: '2d',
      label: t('visualization.2d.title'),
      children: (
        <div className={styles.visualisationContent}>
          <img src="/mock-images/2d-weergave-kas.png" alt="2D Kaart Weergave" />
        </div>
      ),
    },
    {
      key: '3d',
      label: t('visualization.3d.title'),
      children: (
        <div className={styles.visualisationContent}>
          <img src="/mock-images/3d-weergave-kas.png" alt="3D Kaart Weergave" />
        </div>
      ),
    },
  ];

  const toggleFullscreen = () => {
    if (isFullscreen) {
      setDimensions({ width: 600, height: 500 });
      setIsMinimized(false);
    } else {
      setDimensions({ width: 900, height: 800 });
    }
    setIsFullscreen(!isFullscreen);
  };

  const minimizedStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    height: 'auto',
  };

  return (
    <Draggable handle=".handle" bounds="parent">
      <div className={styles.windowWrapper} style={{ height: isMinimized ? 'auto' : `${dimensions.height}px` }}>
        <div className={`${styles.windowHeader} handle`}>
          <span>{t('visualization.title')}</span>
          <div className={styles.controls}>
            {!isMinimized && <Button type="text" icon={<FontAwesomeIcon icon={faMinus} />} onClick={() => setIsMinimized(true)} />}
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faExpand} />}
              onClick={isMinimized ? () => setIsMinimized(false) : toggleFullscreen}
            />
          </div>
        </div>

        {!isMinimized && (
          <ResizableBox
            width={dimensions.width}
            height={dimensions.height}
            onResize={(e, { size }) => {
              setDimensions({
                width: size.width,
                height: size.height,
              });
            }}
            minConstraints={[400, 500]}
            maxConstraints={[900, 800]}
            resizeHandles={['w', 'e', 'n', 's']}>
            <div className={styles.windowContent}>
              <Tabs activeKey={activeTab} items={items} onChange={key => setActiveTab(key as TabKey)} />
            </div>
          </ResizableBox>
        )}
      </div>
    </Draggable>
  );
};
